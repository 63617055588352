<template>
    <div style="background-color: #171726;min-height: 100vh;">
        <nav-bar class="title_top" :icon="'img/backw.png'" :textColor="'#ffffff'" :backColor="'none'" />
        <div class="card_box">
            <div class="card" style="background-image:url('img/myIncome/cash_card.png')"></div>
            <div class="card_text">
                <div>{{ '银行卡账户' + this.walletInfo.cheques_number }}</div>
                <div>{{ this.walletInfo.cheques_name }}</div>
            </div>
        </div>
        <div class="input_content">
            <div class="input_box">
                <input v-model="withdrawMoney" class="input_item" type="number" placeholder="请输入提现收益">
                <div class="money">{{ '收益余额：' + money }}</div>
            </div>
        </div>
        <div class="btn_box_">
            <div class="btn_item" @click="withdrawAct">立即提现</div>
        </div>
        <div class="mask" v-if="showPasswordModal">
            <div class="modal_content" style="background-image:url('img/myIncome/modal_bg.png')">
                <div class="modal_title">密码验证</div>
                <div class="input_item_password_box">
                    <input v-model="password" class="input_item_password" type="number" placeholder="请输入二级安全密码">
                </div>
                <div class="btns_box">
                    <div class="btn_item_cancel" @click="showPasswordModal = false">取消</div>
                    <div class="btn_item_enter" @click="confirm">确定</div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import "@/assets/css/base.css";
import "@/assets/css/index.css";
import NavBar from "@/components/navBar.vue";
import { userWallet, moduleDisplay, walletIndex,applyWithdraw } from "@/api/wallet";
export default {
    components: {
        NavBar,
    },
    data() {
        return {
            walletInfo: {},
            money: '',
            password: '',
            moduleData: {},
            withdrawMoney: '',
            showPasswordModal: false
        }
    },
    created() {
        this.getWalletIndex()
        this.getUserWallet()
        this.moduleDisplay()
    },
    methods: {
        getWalletIndex() {
            walletIndex({}).then((res) => {
                console.log(res);
                this.walletInfo = res.data.account_list[1]
            });
        },
        getUserWallet() {
            userWallet({})
                .then(
                    (response) => {
                        this.money = response.data.money;
                    },
                    (error) => {
                        console.log(error);
                        this.$toast.fail(error);
                    }
                )
                .catch((error) => {
                    this.$toast.fail(error);
                });
        },
        moduleDisplay() {
            moduleDisplay({}).then((res) => {
                this.moduleData = res.data;
            });
        },
        doWithDraw(){
            let params={
                amount:this.withdrawMoney,
                password:this.password,
                cheques:2
            }
            applyWithdraw(params).then(
                    (response) => {
                        console.log(response)
                        this.$toast.success('提现申请提交成功');
                        this.withdrawMoney = ''
                        this.password = ''
                        this.showPasswordModal = false; 
                        this.getUserWallet()
                    },
                    (error) => {
                        console.log(error)
                        this.$toast.fail(error);
                    }
                )
                .catch((error) => {
                    this.$toast.fail(error);
                });
        },
        confirm(){
            if(this.password==''){
                this.$toast.fail('请输入二级密码');
                return false;
            }
            this.doWithDraw()
        },
        withdrawAct(){
            if(!this.walletInfo.cheques_number){
                this.$toast.fail('请先绑定银行卡');
                return false;
            }
            if(this.withdrawMoney == ''){
                this.$toast.fail('请输入提现金额');
                return false;
            }
            if(parseInt(this.withdrawMoney) > this.money){
                this.$toast.fail('提现金额不能大于余额');
                return false;
            }
            if(parseInt(this.withdrawMoney)<100){
                this.$toast.fail('提现金额最小为100');
                return false;
            }
            if(this.moduleData.password_verify==1){
                this.showPasswordModal = true;  
            }else{
                this.doWithDraw()
            }
            // if(this.password == ''){
            //     this.showPasswordModal = true;
            //     return false;
            // }
            // this.withdrawMoney = this.withdrawMoney.replace(/[^\d]/g, '');
            // if(this.withdrawMoney == ''){
            //     this.$toast.fail('请输入提现金额');
            //     return false;
            // }
            // if(this.withdrawMoney > this.money){
            //     this.$toast.fail('提现金额不能大于余额');
            //     return false;
            // }
            // if(this.password == ''){
            //     this.showPasswordModal = true;
            //     return false;
            // }   

        }
    }
}
</script>
<style scoped>
.card_box {
    height: 108px;
    padding: 0 40px 0 40px;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.card {
    width: 108px;
    height: 108px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.card_text {
    flex-grow: 1;
    padding: 0 20px 0 20px;
}

.card_text div {
    line-height: 54px;
    color: #ffffff;
    font-size: 24px;
    letter-spacing: 2px;
}

.input_content {
    padding: 0 40px 0 40px;
}

.input_box {
    padding: 0 40px 0 40px;
    display: flex;
    align-items: center;
    height: 80px;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, .04);
    margin-top: 60px;
}

.input_item {
    border: none;
    display: block;
    height: 100%;
    font-size: 24px;
    background: transparent;
    color: #999999;
}

.money {
    font-size: 24px;
    color: #999999;
    text-align: right;
    flex-grow: 1;
}

.btn_box_ {
    padding: 0 40px 0 40px;
}

.btn_item {
    width: 100%;
    height: 80px;
    background-color: #51D9FF;
    color: #111111;
    line-height: 80px;
    text-align: center;
    font-size: 28px;
    border-radius: 40px;
    margin-top: 60px;
}

.mask {
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
}

.modal_content {
    width: 640px;
    height: 500px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 540px auto 0;
    overflow: hidden;
    z-index: 999;
}

.modal_title {
    text-align: center;
    font-size: 34px;
    color: #111111;
    margin-top: 40px;
}

.input_item_password_box {
    padding: 0 40px 0 40px;
    height: 108px;
}

.input_item_password {
    border: none;
    display: block;
    height: 100%;
    width: 100%;
    font-size: 30px;
    background: rgba(216, 216, 216, 1);
    color: #111111;
    border-radius: 20px;
    margin-top: 100px;
    text-indent: 40px;
}

.btns_box {
    height: 98px;
    display: flex;
    justify-content: space-between;
    margin-top: 64px;
    padding: 0 40px 0 40px;
}

.btn_item_cancel {
    width: 236px;
    height: 98px;
    color: #111111;
    background-color: #d8d8d8;
    line-height: 98px;
    text-align: center;
    font-size: 30px;
    border-radius: 20px;
}

.btn_item_enter {
    width: 236px;
    height: 98px;                                                        
    color: #111111;
    background-color: #51D9FF;
    line-height: 98px;
    text-align: center;
    font-size: 30px;
    border-radius: 20px;
}
</style>